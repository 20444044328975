<template>
  <div>
    <dx-data-grid
      ref="detailGrid"
      key-expr="Id"
      :show-borders="true"
      :data-source="dataSource"
      column-width="auto"
      :columns="columns"
      show-column-lines="true"
      show-row-lines="true"
      width="100%"
      focusedEnabled="true"
      row-alternation-enabled="true"
      @initialized="onGridInitialized"
    >
      <dx-selection mode="single"></dx-selection>
      <dx-header-filter :visible="true" />
      <dx-search-panel :visible="true"></dx-search-panel>
      <dx-paging :page-size="pageSettings.pageSize" />
      <dx-group-panel :visible="true" />
      <dx-pager
        v-if="pageSettings.showPager"
        :show-page-size-selector="true"
        :allowed-page-sizes="pageSettings.pagerSizes"
        :show-info="true"
      />
      <dx-summary>
        <template v-for="(item, i) in totalItems" :key="`summary_${i}`">
          <dx-total-item
            :column="item.column"
            :summary-type="item.summaryType"
            :skip-empty-values="item.skipEmptyValues"
            :value-format="item.valueFormat"
            :display-format="item.displayFormat"
            :align-by-column="item.alignByColumn"
          ></dx-total-item>
        </template>
      </dx-summary>
      <dx-export
        :enabled="exportSettings.enabled"
        :allow-export-selected-data="exportSettings.allowExportSelectedData"
        :file-name="exportSettings.fileName"
        :texts="exportSettings.texts"
        :customize-excel-cell="exportSettings.customizeExcelCell"
      />
    </dx-data-grid>
  </div>
</template>

<script>
import CloudFun, { defineComponent, reactive, ref } from "@cloudfun/core";

import {
  DxDataGrid,
  DxColumnFixing,
  DxSummary,
  DxTotalItem,
  DxPaging,
  DxPager,
  DxHeaderFilter,
  DxSearchPanel,
  DxGroupPanel,
  DxExport,
  DxSelection,
} from "devextreme-vue/data-grid";
export default defineComponent({
  components: {
    DxDataGrid,
    DxSummary,
    DxTotalItem,
    DxPaging,
    DxPager,
    DxHeaderFilter,
    DxSearchPanel,
    DxGroupPanel,
    DxExport,
    DxSelection,
  },
  props: {
    id: {
      type: Number,
      require: true,
    },
  },
  setup(props) {
    const detailGrid = ref({});
    const dataSource = ref({});
    const columns = ref([]);
    const totalItems = ref([]);
    const model = CloudFun.current?.model;
    const permission = reactive({
      isExport: model?.user.Permissions.includes("OrderExport"),
    });
    const pageSettings = reactive({
      showPager: true,
      pageSize: 10,
      pagerSizes: [10, 25, 50],
    });
    const exportSettings = reactive({
      enabled: permission.isExport,
      allowExportSelectedData: false,
      texts: { exportAll: "匯出 Excel" },
    });

    const productTypeListSource = model?.enums.ProductTypeList;

    columns.value = [
      // {
      //   dataField: "Product.Number",
      //   caption: "產品編號",
      //   allowSearch: true,
      //   width: 300,
      // },
      {
        dataField: "Hotel.Name",
        caption: "產品名稱",
        allowSearch: true,
        width: 300,
      },
      {
        dataField: "ProductType",
        caption: "產品種類",
        lookup: {
          dataSource: productTypeListSource,
          displayExpr: "Name",
          valueExpr: "Value",
        },
      },
      // {
      //   dataField: "Product.Adult",
      //   caption: "房型",
      // },

      {
        dataField: "Price",
        caption: "單價",
        format: { type: "currency", precision: 0 },
      },
      {
        dataField: "Quantity",
        caption: "數量",
      },
      {
        dataField: "Amount",
        caption: "金額",
        format: { type: "currency", precision: 0 },
      },
      {
        dataField: "DiscountCode",
        caption: "折扣碼",
      },
      {
        dataField: "LastPan",
        caption: "驗證碼",
      },
      {
        dataField: "DiscountStatus",
        caption: "折扣狀態",
      },
    ];

    function reload(items) {
      this.dataSource = items;
    }

    return {
      detailGrid,
      reload,
      dataSource,
      columns,
      totalItems,
      pageSettings,
      exportSettings,
      permission,
    };
  },
});
</script>

<style scoped></style>
