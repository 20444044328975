
import CloudFun, { defineComponent, ref } from "@cloudfun/core";
import Grid, { GridOptions } from "@/cloudfun/components/Grid.vue";
import { VxeFormProps } from "vxe-table";
import SelectBox, {
  SelectBoxOptions,
} from "@/cloudfun/components/SelectBox.vue";
import FileUploader from "@/cloudfun/components/FileUploader.vue";
import { VueUploadItem } from "vue-upload-component";

export default defineComponent({
  components: {
    Grid,
    SelectBox,
    FileUploader,
  },
  setup() {
    const model = CloudFun.current?.model;
    const grid = ref<any>({});
    const uploader = ref<any>({});

    var printColumns = [{ field: "Name" }, { field: "Description" }];

    const gridOptions: GridOptions = {
      id: "user",
      title: "用戶",
      toolbarConfig: {
        custom: true,
        refresh: true,
      },
      printConfig: {
        sheetName: "用戶清單",
        columns: printColumns,
        modes: ["current", "selected", "all"],
      },
      exportConfig: {
        filename: "角色清單",
        type: "csv",
        types: ["html", "csv"],
        mode: "all",
        modes: ["current", "selected", "all"],
        columns: printColumns,
      },
      columns: [
        {
          field: "Name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          filters: [{ checked: false, label: "包含" }],
          slots: { filter: "filter-name" },
        },
        {
          field: "Account",
          title: "帳號",
          showHeaderOverflow: true,
          showOverflow: true,
          resizable: false,
        },
        {
          field: "Email",
          title: "Email",
          showHeaderOverflow: true,
          showOverflow: true,
          resizable: false,
        },
        {
          field: "Status",
          title: "狀態",
          showHeaderOverflow: true,
          showOverflow: true,
          resizable: false,
          formatter: ({ cellValue }) => {
            return Object.values(model!.enums.UserStatus).find(
              (e) => e.Value === cellValue
            ).Name;
          },
        },
        {
          field: "Remark",
          title: "備註",
          showHeaderOverflow: true,
          showOverflow: true,
          resizable: false,
        },
        { field: "Password", visible: false },
        { field: "RoleIds", visible: false },
        { field: "PhotoUri", visible: false, editRender: { name: "$input" } },
      ],
      promises: {
        query: model
          ? (params) => model.dispatch("user/query", params)
          : undefined,
        queryAll: model ? () => model.dispatch("user/query") : undefined,
        save: model
          ? (params) => {
              console.log("params", params);
              return model.dispatch("user/save", params);
            }
          : undefined,
      },
      modalConfig: { width: 600, height: 505 },
    };

    const formOptions: VxeFormProps = {
      titleWidth: 80,
      titleAlign: "right",
      span: 3,
      items: [
        {
          field: "Name",
          title: "名稱",
          span: 12,
          itemRender: { name: "$input", props: { placeholder: "名稱" } },
        },
        {
          field: "Status",
          title: "狀態",
          span: 12,
          itemRender: {
            name: "$select",
            options: Object.values(model!.enums.UserStatus).map((e) => {
              return { label: e.Name, value: e.Value };
            }),
          },
        },
        {
          field: "Account",
          title: "帳號",
          span: 12,
          itemRender: { name: "$input", props: { placeholder: "帳號" } },
        },
        {
          field: "Password",
          title: "密碼",
          span: 12,
          itemRender: {
            name: "$input",
            props: {
              type: "password",
              placeholder: "密碼",
              autocomplete: "new-password",
            },
          },
        },
        {
          field: "Email",
          title: "Email",
          span: 24,
          itemRender: { name: "$input", props: { placeholder: "Email" } },
        },
        {
          field: "RoleIds",
          title: "角色",
          span: 24,
          slots: { default: "column-role-ids" },
        },
        // { field: 'OnlineString', title: '是否在線', span: 12, itemRender: { name: '$input', props: { placeholder: '是否在線', disabled: 'true' } } },
        // { field: 'LoginIp', title: 'IP位址', span: 12, itemRender: { name: '$input', props: { placeholder: 'IP位址', disabled: 'true' } } },
        // { field: 'LoginTimeString', title: '登入時間', span: 12, itemRender: { name: '$input', props: { placeholder: '登入時間', disabled: 'true' } } },
        // { field: 'LogoutTimeString', title: '登出時間', span: 12, itemRender: { name: '$input', props: { placeholder: '登出時間', disabled: 'true' } } },
        {
          title: "頭像",
          span: 24,
          slots: { default: "column-photo-and-remark" },
        },
        {
          align: "right",
          span: 24,
          titleAlign: "left",
          itemRender: {
            name: "$buttons",
            children: [
              { props: { type: "submit", content: "確認", status: "primary" } },
              { props: { type: "reset", content: "重置" } },
            ],
          },
        }, //, events: { click: save }
      ],
      rules: {
        Name: [{ required: true }],
        Email: [
          {
            required: true,
            message: "Email格式錯誤",
            pattern: new RegExp(
              "^\\w+((-\\w+)|(\\.\\w+))*\\@[A-Za-z0-9]+((\\.|-)[A-Za-z0-9]+)*\\.[A-Za-z]+$"
            ),
          },
        ],
        Account: [{ required: true }],
        Password: [
          {
            required: false,
            message: "須8碼以上含大小寫英文、數字",
            pattern: new RegExp(
              "^((?=.{8,}$)(?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).*|(?=.{8,}$)(?=.*\\d)(?=.*[a-zA-Z])(?=.*[!\\u0022#$%&'()*+,./:;<=>?@[\\]\\^_`{|}~-]).*)"
            ),
          },
        ],
        RoleIds: [
          {
            required: true,
            validator: (params) => {
              if (!params.itemValue?.length)
                return new Error("須選擇至少一個以上的角色");
            },
          },
        ],
      },
    };

    const selectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇角色",
      textField: "Name",
      valueField: "Id",
      columns: [
        {
          field: "Name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
        },
      ],
      multiselect: true,
      showHeader: true,
      promises: {
        find: (value) => model!.dispatch("role/find", value), // eslint-disable-line
        query: (params) => model!.dispatch("role/query", params), // eslint-disable-line
      },
    };

    return {
      grid,
      uploader,
      gridOptions,
      formOptions,
      selectorOptions,
      uploadAction: `${process.env.VUE_APP_BACKEND_URL}/api/files`,
    };
  },
  methods: {
    hideBatchDropDown() {
      cash("#batch-dropdown").dropdown("hide");
    },
    async uploaderFilter(
      current: VueUploadItem,
      original: VueUploadItem,
      prevent: any
    ) {
      if (!current || !current.name) return prevent();
      if (!/\.(png|gif|jpg|jpeg)$/i.test(current.name)) {
        alert("未支援此種圖片格式");
        return prevent();
      }
    },
  },
});
