<template>
  <dx-data-grid
    :ref="config.id"
    :key-expr="config.keyFieldName"
    :show-borders="true"
    :data-source="config.dataSource"
    :repaint-changes-only="true"
    :remote-operations="config.remoteOperations"
    :height="config.height"
    :column-auto-width="config.columnAutoWidth"
    :row-template="config.rowTemplate"
    :on-initialized="config.onInitialized"
    :on-content-ready="config.onContentReady"
    :on-init-new-row="config.onInitNewRow"
    :on-editing-start="config.onEditingStart"
    :on-editor-preparing="config.onEditorPreparing"
    :on-editor-prepared="config.onEditorPrepared"
    :on-cell-prepared="config.onCellPrepared"
    :on-row-prepared="config.onRowPrepared"
    :on-row-inserting="config.onRowInserting"
    :on-row-inserted="config.onRowInserted"
    :on-row-updating="config.onRowUpdating"
    :on-row-updated="config.onRowUpdated"
    :on-row-removing="config.onRowRemoving"
    :on-row-removed="config.onRowRemoved"
    :on-row-click="config.onRowClick"
    :on-cell-click="config.onCellClick"
    :on-selection-changed="config.onSelectionChanged"
    :on-toolbar-preparing="config.onToolbarPreparing"
    :on-exporting="config.onExporting"
    :on-exported="config.onExported"
    :on-file-saving="config.onFileSaving"
    :on-context-menu-preparing="config.onContextMenuPreparing"
    :focused-row-enabled="config.focusedEnabled"
    :row-alternation-enabled="true"
    :allow-column-resizing="true"
    :column-min-width="50"
    :allow-reordering="true"
    :column-chooser="config.columnChooser"
    :allow-column-reordering="config.allowColumnReordering"
  >
    <!--
      Do use calculateDisplayValue if the column is lookup.
      It will defer the loading of lookup data source until user starts editing the column.
      Hint: Use it and set it to any value while the column don't need to see in view mode.
    -->
    <!-- 
      :visibleIndex should be the value between 0 to 1
      :set-cell-value is not applicable in our edit style. (Details: https://www.devexpress.com/Support/Center/Question/Details/T682466/datagrid-how-to-change-a-cell-value-based-on-a-value-from-another-cell)
    -->
    <!--
       The "editor-options" is used when edit mode is row or batch.
    -->
    <dx-column
      v-for="(c, index) in config.columns"
      :key="'gridColumn_' + index"
      :data-field="c.dataField"
      :name="c.name"
      :caption="(c.label || {}).text"
      :data-type="c.dataType"
      :width="c.width"
      :visible="c.visible"
      :visible-index="c.visibleIndex"
      :fixed="c.fixed"
      :format="c.format"
      :sort-index="c.sortIndex"
      :sort-order="c.sortOrder"
      :group-index="c.groupIndex"
      :allow-search="c.allowSearch"
      :allow-editing="c.allowEditing"
      :cell-template="c.cellTemplate"
      :edit-cell-template="c.editCellTemplate"
      :calculate-cell-value="c.calculateCellValue"
      :calculate-display-value="c.calculateDisplayValue"
      :calculateSortValue="c.calculateSortValue"
      :editor-options="(c.editorSettings || {}).editorOptions"
      :css-class="c.cssClass"
    >
      <!-- 
        The lookup data source option doesn't accept a DataSource instance. 
        It accepts a DataSource Configuration object instead.
        The lookup in data grid only accepts four attributes below.
        Note: Can't specify display-expr and value-expr as function if widget is bound to remote data source.
      -->
      <dx-lookup
        v-if="c.lookupOptions"
        :data-source="c.lookupOptions.dataSource"
        :display-expr="c.lookupOptions.displayExpr"
        :value-expr="c.lookupOptions.valueExpr"
        :allow-clearing="c.lookupOptions.allowClearing"
      />
    </dx-column>
    <dx-column :buttons="commandColumnButtons" type="buttons" />
    <template v-for="c in customTemplates" v-slot:[c]="{ data }">
      <slot :name="c" :data="data" />
    </template>
    <dx-selection
      v-if="config.selection"
      :mode="config.selection.mode"
      :select-all-mode="config.selection.selectAllMode"
      show-check-boxes-mode="always"
      :allow-select-all="config.selection.allowSelectAll"
    />
    <dx-filter-row :visible="config.showFilterRow" />
    <dx-filter-panel :visible="config.showFilterPanel" />
    <dx-header-filter :visible="config.showHeaderFilter" />
    <dx-group-panel :visible="config.showGroupPanel" />
    <dx-search-panel
      :visible="config.showSearchPanel"
      :highlight-case-sensitive="true"
      :highlight-search-text="true"
    />
    <dx-scrolling :mode="config.scrollMode" />
    <dx-summary v-if="config.summarySettings">
      <template v-for="(item, i) in config.summarySettings.items">
        <dx-group-item
          v-if="item.type === 'group'"
          :key="'gridSummary_' + i"
          :show-in-group-footer="item.showInGroupFooter"
          :align-by-column="item.alignByColumn"
          :column="item.column"
          :summary-type="item.summaryType"
          :value-format="item.valueFormat"
          :display-format="item.displayFormat"
        />
        <dx-total-item
          v-else-if="item.type === 'total'"
          :key="'gridSummary_' + i"
          :column="item.column"
          :summary-type="item.summaryType"
          :skip-empty-values="item.skipEmptyValues"
          :value-format="item.valueFormat"
          :display-format="item.displayFormat"
          :align-by-column="item.alignByColumn"
        ></dx-total-item>
      </template>
    </dx-summary>
    <dx-paging :page-size="config.pageSettings.pageSize" />
    <dx-pager
      v-if="config.pageSettings.showPager"
      :show-page-size-selector="true"
      :allowed-page-sizes="config.pageSettings.pagerSizes"
      :show-info="true"
    />
    <dx-editing
      :allow-adding="config.canCreate"
      :allow-updating="config.canEdit"
      :allow-deleting="config.canDelete"
      :mode="config.editSettings.mode"
      :use-icons="config.editSettings.useIcons"
      :refresh-mode="config.editSettings.refreshMode"
      :texts="config.editSettings.texts"
    >
      <!-- The property "template" of form in grid can't be used instead use property "editCellTemplate" of grid -->
      <dx-form>
        <dx-item :col-count="2" :col-span="2" item-type="group">
          <dx-item
            v-for="(item, cIndex) in formItems"
            :item-type="item.itemType"
            :key="'gridForm_' + cIndex"
            :label="item.label"
            :data-field="item.dataField"
            :name="item.name"
            :visible="item.editorSettings.visible"
            :col-span="item.editorSettings.colSpan"
            :editor-type="item.editorSettings.editorType"
            :editor-options="item.editorSettings.editorOptions"
            :button-options="item.editorSettings.buttonOptions"
            :validation-rules="item.editorSettings.validationRules"
          ></dx-item>
        </dx-item>
      </dx-form>
      <dx-popup
        v-if="config.editSettings.mode === 'popup'"
        :show-title="config.editSettings.popupSettings.showTitle"
        :width="config.editSettings.popupSettings.width"
        :height="config.editSettings.popupSettings.height"
        :title="config.editSettings.popupSettings.title"
        :resizeEnabled="true"
      >
        <!-- <dx-toolbar-item 
            widget="dxButton" 
            location="after"
            toolbar="bottom">
        </dx-toolbar-item>-->
      </dx-popup>
    </dx-editing>
    <dx-master-detail
      :enabled="config.masterDetailSettings.enable"
      :template="config.masterDetailSettings.templateName"
    />
    <template
      v-if="config.masterDetailSettings.enable"
      v-slot:[config.masterDetailSettings.templateName]="{ data }"
    >
      <slot :name="config.masterDetailSettings.templateName" :data="data" />
    </template>
    <dx-export
      :enabled="config.exportSettings.enabled"
      :allow-export-selected-data="
        config.exportSettings.allowExportSelectedData
      "
      :file-name="config.exportSettings.fileName"
      :texts="config.exportSettings.texts"
      :customize-excel-cell="config.exportSettings.customizeExcelCell"
    />
    <dx-state-storing
      :enabled="config.stateStoring.enabled"
      type="localStorage"
      :storage-key="config.stateStoring.storageKey"
    />
  </dx-data-grid>
</template>

<script>
import { defineComponent } from "vue";
import {
  DxColumn,
  DxDataGrid,
  DxEditing,
  DxExport,
  DxFilterRow,
  DxForm,
  DxGrouping,
  DxGroupItem,
  DxGroupPanel,
  DxHeaderFilter,
  DxLookup,
  DxMasterDetail,
  DxPager,
  DxPaging,
  DxPopup,
  DxScrolling,
  DxSearchPanel,
  DxSelection,
  DxSummary,
  DxTotalItem,
  DxFilterPanel,
  DxStateStoring,
} from "devextreme-vue/data-grid";
import { DxItem, DxValidationRule } from "devextreme-vue/form";
import { cloneDeep } from "../../assets/scripts/cloudfun/clone";
import { isArray } from "../../assets/scripts/cloudfun/inspect";
import { useRoute } from "vue-router";
export default defineComponent({
  name: "CfDataGrid",
  components: {
    DxColumn,
    DxDataGrid,
    DxEditing,
    DxExport,
    DxFilterRow,
    DxForm,
    // DxGrouping,
    DxGroupItem,
    DxGroupPanel,
    DxHeaderFilter,
    DxLookup,
    DxMasterDetail,
    DxPager,
    DxPaging,
    DxPopup,
    DxScrolling,
    DxSearchPanel,
    DxSelection,
    DxSummary,
    DxTotalItem,
    DxFilterPanel,
    DxItem,
    // DxValidationRule,
    DxStateStoring,
  },
  props: {
    options: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const route = useRoute();
    const defaultOptions = {
      height: "auto",
      showFilterRow: false,
      showHeaderFilter: true,
      showGroupPanel: true,
      showSearchPanel: true,
      showFilterPanel: true,
      showRefresh: true,
      columnAutoWidth: true,
      focusedEnabled: false,
      allowColumnReordering: true,
      stateStoring: {
        enabled: false,
        storageKey: route.name,
      },
      selection: {
        mode: "single",
        selectAllMode: "allPages",
        allowSelectAll: true,
      },
      canCreate: true,
      canEdit: false,
      canDelete: true,
      scrollMode: "standard", // standard, virtual, infinite
      remoteOperations: true, // if you need to export grouped data, you need to set it to false.
      pageSettings: {
        showPager: true,
        pageSize: 10,
        pagerSizes: [10, 25, 50],
      },
      editSettings: {
        mode: "popup",
        refreshMode: "reshape", // full, reshape, repaint
        useIcons: false,
        popupSettings: {
          showTitle: true,
          title: "建立資料",
        },
      },
      masterDetailSettings: {
        enable: false,
        templateName: "master-detail-template",
      },
      exportSettings: {
        enabled: false,
        allowExportSelectedData: false,
        texts: { exportAll: "匯出 Excel" }, // exportAll、exportSelectedRows、exportTo
      },
      columnChooser: {
        enabled: false,
      },
    };

    function overrideOnEditorPreparing(config, formItems) {
      const enableItemNames = formItems
        .filter(
          (e) =>
            e.dataField &&
            (!e.editorSettings ||
              !e.editorSettings.editorOptions ||
              (!e.editorSettings.editorOptions.disabled &&
                !e.editorSettings.editorOptions.readOnly))
        )
        .map((e) => e.dataField);
      config.onEditorPreparing = (() => {
        const func = config.onEditorPreparing;
        const editorList = {}; // key: component

        return function (e) {
          if (func) func.call(this, e);
          const fieldName = e.dataField;
          if (
            e.parentType === "dataRow" &&
            ~enableItemNames.indexOf(fieldName)
          ) {
            e.editorOptions.onInitialized = (() => {
              const initFunc = e.editorOptions.onInitialized;
              return function (args) {
                if (initFunc) initFunc.call(this, args);
                const editor = args.component;
                // determine the order by enableItemNames, not by editorList(order would be different)
                const cIdx = enableItemNames.indexOf(fieldName);
                if (~["dxCheckBox"].indexOf(editor.NAME)) {
                  // don't use registerKeyHandler on dxTextBox and dxTextArea
                  editor.registerKeyHandler("enter", function () {
                    const nextEditor = editorList[enableItemNames[cIdx + 1]];
                    if (nextEditor) nextEditor.focus();
                  });
                }

                editorList[fieldName] = args.component;
              };
            })();
            e.editorOptions.onKeyDown = (() => {
              const keyDownFunc = e.editorOptions.onKeyDown;
              return function (args) {
                if (keyDownFunc) keyDownFunc.call(this, args);
                if (args.event.key !== "Enter") return;
                const editor = args.component;
                // determine the order by enableItemNames, not by editorList(order would be different)
                // if (~["dxLookUp", "dxSelectBox"].indexOf(editor.NAME))
                //   editor.close();
                // const cIdx = enableItemNames.indexOf(fieldName);

                // const nextEditor = editorList[enableItemNames[cIdx + 1]];
                // if (nextEditor) nextEditor.focus();
              };
            })();
          }
        };
      })();
    }
    function overrideOnToolbarPreparing(config) {
      config.onToolbarPreparing = (() => {
        const func = config.onToolbarPreparing;
        return function (e) {
          if (func) func.call(this, e);
          e.toolbarOptions.items.unshift({
            location: "after",
            widget: "dxButton",
            options: {
              icon: "refresh",
              onClick: this.refresh.bind(this, true),
            },
          });
        };
      })();
    }

    const config = cloneDeep({}, defaultOptions, props.options);
    if (config.scrollMode !== "standard" && config.pageSettings.showPager)
      config.pageSettings.showPager = false;

    let commandColumnButtons = [];
    if (config.canEdit) {
      commandColumnButtons.push("edit");
    }
    if (config.canDelete) {
      commandColumnButtons.push("delete");
    }
    if (config.commandColumnButtons && isArray(config.commandColumnButtons)) {
      commandColumnButtons = commandColumnButtons.concat(
        config.commandColumnButtons
      );
    }

    // Options of config.columns:
    // EditorSettings which wrap custom and devExtreme(editorOptions) options
    // in order to minimize complexity of usage.
    // editorSettings: for cloudfun, editorOptions: for devExtreme
    config.columns =
      config.columns && isArray(config.columns)
        ? config.columns.map((e) => {
            const opt = { ...e };
            opt.editorSettings = { ...opt.editorSettings } || {};
            opt.editorSettings.editorOptions =
              { ...opt.editorSettings.editorOptions } || {};
            opt.editorSettings.validationRules =
              opt.editorSettings.validationRules || [];
            if (opt.editorSettings.isRequired) {
              opt.editorSettings.validationRules.push({ type: "required" });
            }

            // if (opt.dataType && !opt.editorSettings.editorOptions["display-format"]) {
            //   if (opt.dataType.toLowerCase() === "date") {
            //     opt.editorSettings.editorOptions["use-mask-behavior"] = true;
            //     opt.editorSettings.editorOptions["display-format"] = "yyyy/MM/dd";
            //   } else if (opt.dataType.toLowerCase() === "datetime") {
            //     opt.editorSettings.editorOptions["use-mask-behavior"] = true;
            //     opt.editorSettings.editorOptions["display-format"] =
            //       "yyyy/MM/dd HH:mm:ss";
            //   }
            // }

            return opt;
          })
        : [];
    const customTemplates = config.columns
      .filter((e) => e.cellTemplate)
      .map((e) => e.cellTemplate)
      .concat(
        config.columns
          .filter((e) => e.editCellTemplate)
          .map((e) => e.editCellTemplate)
      );
    if (config.rowTemplate) customTemplates.push(config.rowTemplate);
    const formItems = config.columns.filter(
      (e) => e.editorSettings.visible !== false
    );

    config.onRowUpdating = (options) => {  
      if(options.oldData.Profile){
        options.newData = Object.assign(options.oldData, options.newData, {
            Profile: Object.assign(options.oldData.Profile, options.newData.Profile)
          }
        );
      }
      //  else {
      //   options.newData = {...options.oldData, ...options.newData};
      // }
    }; 
    

    overrideOnEditorPreparing(config, formItems);
    if (config.showRefresh) overrideOnToolbarPreparing(config, formItems);

    return {
      config,
      commandColumnButtons,
      customTemplates,
      formItems,
      overrideOnEditorPreparing,
      overrideOnToolbarPreparing,
    };
  },
  methods: {},
});
</script>

<style scoped></style>
